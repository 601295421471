
export default {
  props: {
    
  },
  data() {
    return {
      
    };
  },
  computed: {
    
  },
  methods: {
    search () {
      this.$emit('search');
    }
  },
};
