import moment from "moment";

// 订单列表
import req_list_data from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_queryHotelOrderListV2";
// 取消订单
import req_cancel_order from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_cancelOrder";

import EmptyReminder from "../../components/empty-reminder/index.vue";
import HotelRefund from "@/component/hotelRefund/index.vue";

export default {
  components: {
    HotelRefund,
    EmptyReminder,
  },
  data() {
    return {
      loading: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        orderNo: "",
        evectionUserName: "",
        status: "",
        evectionType: "",
      },
      list: [],
      allocation: {
        status: [
            // 全部/超标审批中/待支付/待确认/已出房/出房失败/退订中/退订成功/退订失败/已取消
          { label: "全部", value: "" },
          { label: "超标审批中", value: "18" },
          { label: "待支付", value: "0" },
          { label: "待确认", value: "2" },
          { label: "已出房", value: "11" },
          { label: "出房失败", value: "14" },
          { label: "退订中", value: "15" },
          // { label: "部分退订成功", value: "22" },
          { label: "退订成功", value: "16" },
          { label: "退订失败", value: "13" },
          { label: "已取消", value: "6" },
        ],
        evectionType: [
          { label: "全部", value: "" },
          { label: "个人预定", value: "1" },
          { label: "出差单预定", value: "2" },
        ],
        pageSize: [10, 15, 20, 25, 30],
        total: 0,

        cancelForm: {
          orderNo: "",
          cancelRemark: "",
        },
      },
      pager: {
        total: 0,
        pageSizes: [10, 15, 20, 25, 30],
      },
    };
  },
  computed: {},
  methods: {
    req_list() {
      this.loading = true;
      this.list = [];
      req_list_data(this.form)
        .then((res) => {
          this.list = res.pageResult.pageData;
          if (this.list) {
            this.list.forEach(item => {
              item.isBeforeCheckIn = moment().add(-1, 'd').isBefore(item.checkInDate, 'day')
              console.log(item.checkInDate);
              //item.isBeforeCheckIn = moment().add(-1, 'd').isBefore('2024-07-15', 'day')
              console.log(item.isBeforeCheckIn)
            })
          }
          this.pager.total = res.pageResult.totalCount;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    init() {
      const currentPage = Number(this.$route.query.currentPage);
      if (currentPage) {
        this.form.currentPage = currentPage;
        this.pager.total = currentPage * 10;
        this.$store.commit({
          type: "update_hotel_list_currentPage",
          currentPage,
        });
      }
      this.req_list();
    },
    cancel(orderNo) {
      this.$confirm("是否确认取消订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          req_cancel_order({ orderNo, cancelRemark: "" })
            .then((res) => {
              this.$message.success("取消订单成功");
              this.req_list();
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    search() {
      if (this.loading) return;
      this.form.currentPage = 1;
      this.req_list();
    },
    to_detail(orderNo) {
      this.$router.push({
        name: "admin-hotel-order-detail",
        query: {
          orderNo,
          // listType: '1',
        },
      });
    },
    to_payment(item) {
      if (item.evectionType === 1) {
        this.$router.push({
          name: 'admin-personal-hotel-submit-order',
          query: {
            orderNo: item.orderNo,
          },
        });
      } else {
        this.$router.push({
          name: 'admin-pay',
          query: {
            orderNos: item.orderNo,
            orderType: "3",
          },
        });
      }

    },
    to_refund(val) {
      val.loading = true;
      let params = { orderNo: val.orderNo, evectionNo: val.evectionNo, cancelRemark: '' };
      this.$refs.aHotelRefund.clickUnsubscribe(params).then(res=>{
        val.loading = false;
        this.$forceUpdate();
        if (res){
          this.req_list();
        }
      }, error => {
        val.loading = false;
      }).catch(e=>{
        val.loading = false;
      });
      /*this.$router.push({
        name: "admin-hotel-order-refund",
        query: {
          orderNo,
          // listType: '1',
        },
      });*/
    },
    to_refund_detail(orderNo) {
      this.$router.push({
        name: "admin-hotel-refund-detail",
        query: {
          orderNo,
          // listType: '1',
        },
      });
    },
    handleSizeChange(pageSize) {
      this.form.pageSize = pageSize;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.form.currentPage = currentPage;
      // this.$store.commit({
      //   type: "update_hotel_list_currentPage",
      //   currentPage,
      // });
      this.req_list();
    },
    // 订单状态
    getStatusColor(val) {
      if (val === 0 || val === 2 || val === 18) {
        return 'orange'
      } else if (val === 11) {
        return 'green'
      } else {
        return 'red'
      }
    },
  },
  mounted() {
    console.log(8);
    this.req_list();
    // this.init();
  },
  filters: {
    format_time(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    format_week(val) {
      switch (moment(val).day()) {
        case 1:
          return "周一";
          break;
        case 2:
          return "周二";
          break;
        case 3:
          return "周三";
          break;
        case 4:
          return "周四";
          break;
        case 5:
          return "周五";
          break;
        case 6:
          return "周六";
          break;
        case 0:
          return "周日";
          break;
      }
    },
    format_person(val) {
      if (val) {
        return JSON.parse(val)
          .map((item) => item.lastName)
          .join("/");
      }
    },
    format_status(val) {
      switch (Number(val)) {
        case 0:
          return "待支付";
          break;
        case 1:
          return "支付中";
          break;
        case 2:
          return "已支付，待出房";
          break;
        case 4:
          return "已支付，待入住";
          break;
        case 6:
          return "已取消";
          break;
        case 7:
          return "取消中";
          break;
        case 8:
          return "取消失败";
          break;
        case 9:
          return "预下单";
          break;
        case 10:
          return "创建订单失败";
          break;
        case 11:
          return "已出房";
          break;
        case 12:
          return "已退款";
          break;
        case 13:
          return "退款失败";
          break;
        case 14:
          return "出房失败";
          break;
        case 15:
          return "退款中";
          break;
        // case 16:
        //   return "已退房";
        //   break;
        // case 17:
        //   return "已退房";
        //   break;
        case -1:
          return "已删除";
          break;
      }
    },
  },
};
